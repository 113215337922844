import { Link } from 'gatsby';
import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';
import { parseDate } from '../utils/parseDate';
import { parseQuery } from '../utils/parseQuery';
import { colors } from '../utils/siteVars';
import BtnNoStyle from './BtnNoStyle';
import Button from './Button';
import Card from './Card';
import OverlayBox from './OverlayBox';
import { parseCustomerName } from '../utils/parseCustomerName';
import { request } from '../utils/request';
import { getProtocolFilename } from '../utils/getProtocolFilename';
import { downloadPdf } from '../utils/downloadPdf';
import CreateProtocolModal from './CreateProtocolModal';
import { ProtocolBM, ReservoirBM } from '@bm-js/h2o-shared';

const StyledReservoirProtocolsList = styled.div`
  .customer-card-header-right-content {
    display: flex;
    align-items: flex-start;
    select {
      margin-right: 0.5rem;
    }
    button {
      white-space: nowrap;
      padding: 0.6rem;
    }
  }
  .protocol-card-outer {
    display: grid;
    grid-template-columns: 1fr 30px;
    align-items: center;
    gap: 0.5rem;
    img {
      width: 100%;
      margin: 0;
    }
    .protocol-button-container {
      position: relative;
      margin-top: 1rem;
      .protocol-menu-container {
        position: absolute;
        top: 1.2rem;
        right: 0;
        padding: 0.5rem;
        background: ${colors.white};
        border-radius: 10px;
        box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1);
        pointer-events: none;
        opacity: 0;
        z-index: 2;
        transition: 100ms ease-in-out;
        button {
          width: 100%;
          margin-bottom: 0.5rem;
          white-space: nowrap;
        }
      }
      &:hover {
        .protocol-menu-container {
          opacity: 1;
          pointer-events: initial;
        }
      }
    }
  }
`;

type Props = {
  reservoir: ReservoirBM;
  customerName: string;
  protocolsWithPDF: string[];
};

const ReservoirProtocolsList = (props: Props) => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const { reservoir } = props;
  const { protocols } = reservoir;
  const [filter, setFilter] = useState('Alla');
  const [deleteProtocolPending, setDeleteProtocolPending] = useState<{
    active: boolean;
    protocol: ProtocolBM | {};
  }>({
    protocol: {},
    active: false,
  });
  const [createProtocolModalOpen, setCreateProtocolModalOpen] = useState(false);

  const getProtocolPdf = async (
    id: string,
    type: string,
    date: Date,
    typeFull: string
  ) => {
    const customerName = parseCustomerName(props.customerName);
    const reservoirName = parseCustomerName(reservoir.basicInformation.name);
    const path = `protocols/{token}/protocol-pdf/${customerName}/${reservoirName}/${id}/${type}`;
    const { data } = await request({
      state,
      dispatch,
      path,
      isDoServer: true,
    });
    const filename = getProtocolFilename(
      reservoir.basicInformation.name,
      props.customerName,
      typeFull,
      date
    );
    // @ts-ignore
    downloadPdf(data.data, filename, true);
  };

  const previewPdf = async (protocol: ProtocolBM) => {
    const customerName = parseCustomerName(props.customerName);
    const reservoirName = parseCustomerName(reservoir.basicInformation.name);
    const path = `protocols/{token}/protocol-pdf/${customerName}/${reservoirName}/${protocol._id}/${protocol.type}`;

    const { data } = await request({
      state,
      dispatch,
      path,
      isDoServer: true,
    });
    // @ts-ignore
    downloadPdf(data.data);
  };

  const deleteProtocol = async () => {
    const customerId = parseQuery(window.location)?.customerId;
    if (!customerId) return;
    const mongoPath = `customers/protocol-delete/${customerId}/${reservoir._id}/${(deleteProtocolPending.protocol as ProtocolBM)._id}`;

    await request({
      state,
      dispatch,
      path: mongoPath,
      method: 'DELETE',
    });

    const customerName = parseCustomerName(props.customerName);
    const reservoirName = parseCustomerName(reservoir.basicInformation.name);
    const doPath = `protocols/{token}/protocol-delete/${customerName}/${reservoirName}/${(deleteProtocolPending.protocol as ProtocolBM)._id}/${(deleteProtocolPending.protocol as ProtocolBM).type}/${customerId}/${reservoir._id}`;

    await request({
      state,
      dispatch,
      path: doPath,
      isDoServer: true,
      method: 'DELETE',
    });

    window.location.reload();
  };

  const filteredProtocols = useMemo(() => {
    if (filter === 'Alla') return protocols;
    return protocols.filter(
      (protocol) =>
        parseDate(protocol.dateForInspection)?.split('-')[0] === filter
    );
  }, [protocols, filter]);

  const sortedProtocols = useMemo(() => {
    return filteredProtocols.sort((a, b) => {
      return (
        new Date(b.dateForInspection).getTime() -
        new Date(a.dateForInspection).getTime()
      );
    });
  }, [filteredProtocols]);

  const years = useMemo(() => {
    if (!protocols?.length) return [];

    const years: number[] = [];
    protocols.forEach((protocol) => {
      const year = new Date(protocol.dateForInspection).getFullYear();
      years.push(year);
    });
    const yearSet = new Set(years);
    const yearsUnique = Array.from(yearSet);
    return yearsUnique;
  }, [protocols]);

  return (
    <StyledReservoirProtocolsList>
      <Card>
        <div className="customer-card-header">
          <h3>Protokoll</h3>
          <div className="customer-card-header-right-content">
            {/* eslint-disable-next-line jsx-a11y/no-onchange */}
            <select value={filter} onChange={(e) => setFilter(e.target.value)}>
              <option>Alla</option>
              {years.map((year) => (
                <option key={year}>{year}</option>
              ))}
            </select>
            <Button
              small
              className="nowrap"
              onClick={() => setCreateProtocolModalOpen(true)}
            >
              Skapa protokoll
            </Button>
          </div>
        </div>

        {sortedProtocols.map((protocol) => (
          <div className="protocol-card-outer" key={protocol._id}>
            <Card inner className="protocol-card">
              {protocol.typeFull} {parseDate(protocol.dateForInspection)}{' '}
              {protocol.status}
            </Card>
            <div className="protocol-button-container">
              <BtnNoStyle>
                <img src="/icons/3-dots.png" alt="" />
              </BtnNoStyle>
              <div className="protocol-menu-container">
                <Link
                  to={`/create-protocol/${protocol.type}/?customerId=${
                    parseQuery(window.location)?.customerId
                  }&customerName=${props.customerName}&reservoirId=${
                    reservoir._id
                  }&reservoirName=${reservoir.basicInformation.name}&type=${
                    protocol.typeFull
                  };${protocol.type}&protocolId=${protocol._id}`}
                  state={{ protocol }}
                >
                  <Button small>Redigera</Button>
                </Link>
                {props.protocolsWithPDF.indexOf(protocol._id) !== -1 ? (
                  <>
                    <Button
                      small
                      onClick={() => {
                        getProtocolPdf(
                          protocol._id,
                          protocol.type,
                          protocol.dateForInspection,
                          protocol.typeFull
                        );
                      }}
                    >
                      Hämta PDF
                    </Button>
                    <Button
                      small
                      onClick={() => {
                        previewPdf(protocol);
                      }}
                    >
                      Visa PDF
                    </Button>
                  </>
                ) : null}
                <Button
                  small
                  look="danger"
                  onClick={() => {
                    setDeleteProtocolPending({
                      active: true,
                      protocol: protocol,
                    });
                  }}
                >
                  Radera
                </Button>
              </div>
            </div>
          </div>
        ))}
      </Card>
      <OverlayBox
        active={deleteProtocolPending.active}
        close={() =>
          setDeleteProtocolPending({ ...deleteProtocolPending, active: false })
        }
        header="Radera protokoll?"
      >
        <p>
          Vill du <strong>permanent radera</strong> protokollet?
          <br />
          Det går <strong>inte</strong> att återskapa ett raderat protokoll.
        </p>
        <Button med look="danger" onClick={deleteProtocol}>
          Radera
        </Button>
        <Button
          med
          onClick={() => {
            setDeleteProtocolPending({
              ...deleteProtocolPending,
              active: false,
            });
          }}
        >
          Tillbaka
        </Button>
      </OverlayBox>
      {createProtocolModalOpen && (
        <CreateProtocolModal
          close={() => setCreateProtocolModalOpen(false)}
          reservoir={props.reservoir}
          customer={{
            // @ts-ignore
            customerInformation: { name: props.customerName },
            _id: parseQuery(window.location)?.customerId,
          }}
        />
      )}
    </StyledReservoirProtocolsList>
  );
};

export default ReservoirProtocolsList;
